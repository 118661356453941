/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyDIllWVHJMtC56Zns1DoWRQFlOHhnj8CT8",
  "appId": "1:192439239085:web:5a3c832e1f69a3a382a124",
  "authDomain": "schooldog-i-demo-al.firebaseapp.com",
  "measurementId": "G-6GPX5E2W2H",
  "messagingSenderId": "192439239085",
  "project": "schooldog-i-demo-al",
  "projectId": "schooldog-i-demo-al",
  "showGaBranding": false,
  "storageBucket": "schooldog-i-demo-al.appspot.com"
}
